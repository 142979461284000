"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateDiscountLabel = void 0;
function generateDiscountLabel(onPromotion, price, onPromotionPrice) {
    var _a;
    if (!onPromotion)
        return "";
    var discountLabelPercentage = (_a = ((parseFloat(price) - parseFloat(onPromotionPrice)) / parseFloat(price)) * 100) !== null && _a !== void 0 ? _a : 0;
    return "".concat(discountLabelPercentage.toFixed(0), "% OFF");
}
exports.generateDiscountLabel = generateDiscountLabel;
