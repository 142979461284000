"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutUsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
// import colors from "../../../colors.config";
// import baby_and_wellness from "../../assets/baby_and_wellness.png";
// import health_and_beauty from "../../assets/health_and_beauty.png";
// import mobility from "../../assets/mobility.png";
// import oxygen_hire from "../../assets/oxygen_hire.png";
// import call_center from "../../assets/call_center.png";
// import loyalty_card from "../../assets/loyalty_card.png";
var AboutUsPage = function () {
    var _a;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "w-full -z-10" },
            react_1.default.createElement("div", { className: "bg-secondary" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
                    react_1.default.createElement("div", { className: "flex flex-col items-center" },
                        react_1.default.createElement("div", { className: "w-[400px] md:w-[600px] lg:w-[800px] \n                flex flex-col gap-y-4 pt-5" },
                            react_1.default.createElement("div", { className: "py-20 flex flex-col items-center gap-8" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "About ".concat((_a = process.env.APP_NAME) !== null && _a !== void 0 ? _a : ""), size: "3xlarge", textColor: "text-white", center: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Carved out of the fashion ethos of 2015, Taffy Accessories has become a sanctuary for men seeking to inject a dose of prestige into their attire. We cater to every man's style, be it sharply formal or comfortably casual, with an exquisite collection of clothing and accessories. Handcrafted by the finest artisans from Turkey, each piece we offer is a statement of quality and confidence. At Taffy Accessories, we don't just clothe you - we strive to make all our clients look and feel confident in their style.", size: "medium", textColor: "text-white", center: true })))))),
            react_1.default.createElement("div", { className: "-mt-[1px]" },
                react_1.default.createElement("svg", { viewBox: "0 0 20 1.5", xmlns: "http://www.w3.org/2000/svg" },
                    react_1.default.createElement("path", { d: "M 0 0 L 20 0 Q 10 3 0 0", fill: "bg-secondary" })))),
        react_1.default.createElement("div", { className: "" }),
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null)));
};
exports.AboutUsPage = AboutUsPage;
// const sampleMissions = [
//   {
//     title: "Our Company",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/150",
//   },
//   {
//     title: "Our Vision",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/150",
//   },
//   {
//     title: "What we really do",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/250",
//   },
// ];
// const sampleDoctors = [
//   {
//     title: "Baby and Wellness Clinic",
//     description: "",
//     image: baby_and_wellness,
//   },
//   {
//     title: "Health and Beauty Clinic",
//     description: "",
//     image: health_and_beauty,
//   },
//   {
//     title: "Mobility Aids & CPAP machines & equipment",
//     description: "",
//     image: mobility,
//   },
//   {
//     title: "Wheelchair Hire & Oxygen Hire",
//     description: "",
//     image: oxygen_hire,
//   },
//   {
//     title: "Chronic Call Centre & Blister Packing",
//     description: "",
//     image: call_center,
//   },
//   {
//     title: "Loyalty Card",
//     description: "",
//     image: loyalty_card,
//   },
// ];
