"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateForm = void 0;
function validateForm(input, customValidation) {
    if (customValidation === void 0) { customValidation = {}; }
    var errors = {};
    Object.entries(input).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        var expectedType = typeof value;
        var validation = customValidation[key];
        if (value !== undefined && (validation === null || validation === void 0 ? void 0 : validation.dependOn)) {
            var _b = validation.dependOn, ckey = _b.ckey, cvalue = _b.cvalue;
            if (input[ckey] !== cvalue) {
                if (value === null || value === undefined || value === "") {
                    errors[key] = "".concat((validation === null || validation === void 0 ? void 0 : validation.name) || ckey.replace(/_/g, " "), " as it depends on ").concat(ckey.replace(/_/g, " "));
                }
            }
        }
        else {
            if (value !== undefined) {
                if (value === null || value === "") {
                    errors[key] = "".concat((validation === null || validation === void 0 ? void 0 : validation.name) || key.replace(/_/g, " "), " is required");
                }
                else if (key === "email" && !validateEmail(value)) {
                    errors[key] = "".concat((validation === null || validation === void 0 ? void 0 : validation.name) || key.replace(/_/g, " "), " must be a valid email address");
                }
                else if ((key === "phone" || key == "contact_number") && !validatePhone(value)) {
                    errors[key] = "".concat((validation === null || validation === void 0 ? void 0 : validation.name) || key.replace(/_/g, " "), " must be a 10-digit phone number");
                }
            }
        }
    });
    var isValid = Object.keys(errors).length === 0;
    return { errors: errors, isValid: isValid };
}
exports.validateForm = validateForm;
function validateEmail(email) {
    // email validation regex
    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}
function validatePhone(phone) {
    // phone number validation regex
    var regex = /^\d{10}$/;
    return regex.test(phone);
}
