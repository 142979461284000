"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setMainModal = exports.setDrawerPage = exports.setDrawerOpen = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    main: {
        isDrawerOpen: false,
        drawerPageName: "login",
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "main",
    initialState: initialState,
    reducers: {
        setDrawerOpen: function (state, action) {
            state.main.isDrawerOpen = action.payload;
        },
        setDrawerPage: function (state, action) {
            state.main.drawerPageName = action.payload;
        },
        setMainModal: function (state, action) {
            var _a;
            state.main.modal = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setDrawerOpen = (_a = slice.actions, _a.setDrawerOpen), exports.setDrawerPage = _a.setDrawerPage, exports.setMainModal = _a.setMainModal;
exports.default = slice.reducer;
